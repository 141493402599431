import React, { useState, useEffect } from 'react';
import {useSearchParams} from "react-router-dom";

const UnsubscribePage = () => {
    const [result, setResult] = useSearchParams();
    let response = result.get("result");

    return (
        <div className="relative overflow-hidden min-h-screen flex items-center justify-center">
            <div className="relative flex justify-center">
                <div className="relative flex-shrink-0">
                    {/*<img
                        src="images/classic/splash-art.webp"
                        alt="Bombergrounds Classic"
                        className="sm:max-lg:w-[100vh] h-[60vh] lg:h-[100vh]"
                    />*/}
                </div>
            </div>
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-center">
                    <h1 className="font-style-game-title text-white text-5xl lg:text-7xl">
                        {response}
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default UnsubscribePage;